import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { ResponsiveProductCardsWrapper } from '@components/main/elements/product-category/responsive-product-cards.styles';
import { ProductCard } from '@components/product/elements/product-card/product-card';
import { ProductCardImage } from '@components/product/elements/product-card/product-card.style';

export const ProductCategoryLeft = styled.div`
  width: 262px;

  @media (max-width: 480px) {
    flex-direction: row;
    margin-bottom: 4px !important;
    align-items: flex-end !important;

    h2 {
      flex: 1;
    }
  }
`;

export const ProductCategoryRight = styled.div<{ count: number }>`
  position: relative;
  top: -30px;
  width: 1148px;
  margin-left: auto;

  .swiper-wrapper {
    padding: 10px;
  }

  @media (max-width: 1440px) {
    top: 0;
    width: calc(100% + 44px);
    position: relative;
    left: -30px;
    padding-left: 10px;

    .swiper-wrapper {
      padding: 0;
    }
  }

  @media (max-width: 1000px) {
    width: ${({ count }) => (count > 2 ? 'calc(100% + 60px)' : '100%')};
    position: relative;
    left: -30px;
  }

  @media (max-width: 647px) {
    ${ProductCard} {
      width: ${({ count }) => (count > 2 ? '272px' : '50%')};

      ${ProductCardImage} {
        width: ${({ count }) => (count > 2 ? '232px' : '100%')};
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  @media (max-width: 600px) {
    width: ${({ count }) => (count > 2 ? 'calc(100% + 40px)' : '100%')};
    position: relative;
    left: -20px;
  }

  @media (max-width: 480px) {
    ${ResponsiveProductCardsWrapper} {
      padding-bottom: 0;
    }

    ${ProductCard} {
      min-width: ${({ count }) => (count > 2 ? '212px' : 'unset')};
    }
  }
`;

export const ProductCategoryShowAll = styled.a`
  margin-top: 25px;
  display: none;

  button {
    text-transform: none;
    font-weight: 400;
    letter-spacing: unset;
    background-color: #f9f7fa !important;
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.black} !important;
  }

  @media (max-width: 480px) {
    display: block;
  }
`;

export const ProductCategoryInfo = styled.div`
  min-width: max-content;
  display: flex;
  flex-direction: column;

  @media (max-width: 1440px) {
    flex-direction: row;
  }
`;

export const ProductCategoryTitle = styled.h2`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  color: ${theme.colors.black};
  word-break: break-word;
  //text-transform: capitalize;

  @media (max-width: 1440px) {
    margin-bottom: 0;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ProductCategoryCount = styled.div`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greyPrimary};
  display: flex;
  align-items: flex-end;

  @media (max-width: 1000px) {
    line-height: unset;
  }

  @media (max-width: 480px) {
    margin-right: 0 !important;
    color: ${theme.colors.primary};
  }
`;

export const ProductCategoryShowMore = styled.div`
  font-size: 13px;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${theme.colors.primary};
  margin-top: 8px;

  a {
    color: ${theme.colors.primary};
  }

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1000px) {
    line-height: unset;
    position: relative;
    top: -1px;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export const StyledProductCategory = styled.article`
  margin-top: 80px;
  display: flex;

  &.largeCards {
    flex-direction: column;

    ${ProductCategoryRight} {
      position: unset;
      width: 100%;
    }

    ${ProductCategoryLeft} {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      align-items: center;
    }

    ${ProductCategoryShowMore} {
      display: none;
    }
    ${ProductCategoryCount} {
      margin-left: 16px;
    }
  }

  @media (max-width: 1440px) {
    flex-direction: column;

    ${ProductCategoryCount} {
      margin-right: 16px;
    }

    margin-top: 30px;
  }

  @media (max-width: 480px) {
    margin-top: 24px;
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
