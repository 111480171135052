import React from "react";
import * as S from "./product-categories.styles";
import { ProductCategory } from "@components/main/elements/product-category/product-category";
import { CategoryProductsList } from "@components/category/blocks/category-products-list/category-products-list";
import { BlockTitle } from "@components/ui/block-title/block-title";
import plural from "plural-ru";
import { ProductLargeCards } from "@components/product/blocks/product-large-cards/product-large-cards";
import { PageCatalogQuery } from "../../../../gql/generated";
import { SingleProduct } from "@components/product/elements/single-product/single-product";
import { ListKeys } from "@stores/cookies";

type Props = {
  categories: PageCatalogQuery['categories'];
  onlyCards?: boolean;
  disabledPopularCategories?: boolean;
  listKey: ListKeys;
  smallerMarginTop?: boolean
};

export const ProductCategories = (props: Props) => {
  const countPlural = plural(
    props.categories[0].products.length,
    '%d товар',
    '%d товара',
    '%d товаров'
  );

  if (props.categories.length === 1) {
    if (props.categories[0].products.length === 1) {
      return (
        // @ts-ignore
        <SingleProduct {...props.categories[0].products[0]} />
      );
    }

    return (
      <S.StyledProductCategories className={props.disabledPopularCategories && 'noMarginTop'}>
        <BlockTitle subtitle={`(${countPlural})`}>{props.categories[0].name}</BlockTitle>

        {props.categories[0].products.length > 4 ? (
          <CategoryProductsList products={props.categories[0].products} listKey={props.listKey} />
        ) : (
          <ProductLargeCards products={props.categories[0].products} />
        )}
      </S.StyledProductCategories>
    );
  }

  if (props.categories.length > 1) {
    return (
      <S.StyledProductCategories className={`${props.disabledPopularCategories && 'noMarginTop'} ${props.smallerMarginTop && 'smallerMarginTop'}`}>
        {props.categories.map(
          (category) =>
            category.productsCount > 0 && (
              <ProductCategory key={category.id} {...category} onlyCards={props.onlyCards} />
            )
        )}
      </S.StyledProductCategories>
    );
  }
};
