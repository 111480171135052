import styled from 'styled-components';
import { Label } from '@components/ui/label/label';
import { theme } from '@components/ui/theme';
import { MainVideoImage, MainVideoText } from '@components/main/elements/video/video.style';
import { Description } from '@components/product/blocks/description/description.style';
import { StyledCountPicker } from '@components/ui/count-picker/count-picker.style';

export const StyledSingleProduct = styled.article`
  margin-bottom: 80px;
  position: relative;
  display: flex;
  justify-content: flex-start;

  ${MainVideoText} {
    font-size: 13px;
  }
`;

export const SingleProductStickyWrapper = styled.div`
  position: sticky;
  top: 175px;
  height: fit-content;

  &.image {
    min-width: 350px;
    margin-right: 40px;
  }

  &.price {
    min-width: 206px;
    margin-left: 80px;
  }
`;

export const Labels = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  ${Label} {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
`;

export const SingleProductImageContainer = styled.div`
  margin-bottom: 35px;
  padding: 20px 40px;
  width: 350px;
  height: 264px;
  position: relative;
`;

export const SingleProductImage = styled.div`
  width: 270px;
  height: 224px;
  cursor: pointer;
  
  img {
    max-width: 270px !important;
    max-height: 224px !important;
    min-width: 270px !important;
    min-height: 224px !important;
`;

export const SingleProductImagesSlider = styled.div``;

export const SingleProductMain = styled.div`
  flex: 1;
`;

export const SingleProductBlockTitle = styled.h2`
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  color: ${theme.colors.black};
`;

export const SingleProductBlockWrapper = styled.div`
  margin-top: 20px;

  ${Description} {
    margin-top: 20px;
  }
`;

export const SingleProductVideos = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;

  ${MainVideoImage} {
    height: 85px;
  }

  @media (max-width: 1312px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const SingleProductButton = styled.div`
  margin-top: 20px;

  button {
    width: fit-content;
  }
`;

export const SingleProductPrice = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: ${theme.colors.black};
`;

export const SingleProductPriceOld = styled.span`
  margin-left: 15px;
  font-size: 17px;
  line-height: 120%;
  color: #686a70;
  text-decoration: line-through;
`;

export const SingleProductPriceTop = styled.div`
  display: flex;
  align-items: center;
`;

export const SingleProductPriceRange = styled.div`
  margin-top: 6px;
  font-size: 12px;
  line-height: 130%;
  color: #686a70;
`;

export const SingleProductPriceOffers = styled.div`
  margin-top: 10px;
  color: #686a70;
  font-size: 15px;
  line-height: 140%;
`;

export const SingleProductPriceButtons = styled.div`
  margin-top: 15px;
`;

export const SingleProductPriceToCart = styled.div`
  margin-top: 15px;
  width: min-content;

  @media (min-width: 551px) {
    ${StyledCountPicker} {
      height: 42px;

      button {
        height: 100%;
      }
    }
  }
`;

export const SingleProductTitle = styled.a`
  font-size: 24px;
  font-weight: 500;
  color: ${theme.colors.black};

  &:hover {
    text-decoration: underline;
  }
`;

export const SingleProductMainInfo = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const PackageInfoRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const PackageInfoRatingStar = styled.div`
  color: ${theme.colors.primary};
`;

export const PackageInfoRatingScore = styled.div`
  font-size: 12px;
  color: #302e30;
  margin-left: 6px;
`;

export const PackageInfoRatingValue = styled.div`
  font-size: 12px;
  color: #9ca0a9;
  margin-left: 6px;

  &.offers {
    margin-left: 14px;
    width: fit-content;
  }

  .reviews {
    cursor: pointer;
    &:hover div:last-child {
      border-color: transparent;
    }

    div:last-child {
      padding-top: 2px;
      border-bottom: 1px dotted #9ca0a9;
      line-height: 15px;
    }
  }

  @media (max-width: 450px) {
    &.offers {
      margin-top: 2px;
      flex-basis: 100%;
      margin-left: 0;
    }
  }
`;

export const PackageInfoRatingFavorites = styled.div<{ isFavorite: boolean }>`
  color: ${({ isFavorite }) => (isFavorite ? theme.colors.primary : theme.colors.greyPrimary)};
  margin-left: 17px;
  font-size: 12px;
  cursor: pointer;
  min-width: 107px;

  svg {
    width: 22px;
    height: 22px;
  }
`;
