import React, { useMemo, useState } from 'react';
import * as S from './single-product-styles';
import { SingleProductProps } from '@components/product/elements/single-product/single-product-types';
import { useStore } from '@stores/root';
import { SingleProductImagesSlider } from '@components/product/elements/single-product/single-product-images-slider/single-product-images-slider';
import { Image } from '@components/Image';
import { imageLoader } from '../../../../utils/image-loader';
import { Label } from '@components/ui/label/label';
import { NoSSR } from '@components/NoSSR';
import { TextWithIcon } from '@components/ui/text-with-icon/text-with-icon';
import { IconHeartFilled } from '@components/ui/icons/icon-heart-filled';
import { IconHeart } from '@components/ui/icons/icon-heart';
import plural from 'plural-ru';
import { IconMessage } from '@components/ui/icons/icon-message';
import { ProductInformation } from '@components/product/blocks/information/information';
import { MainVideo } from '@components/main/elements/video/video';
import { ProductDescription } from '@components/product/blocks/description/description';
import { Button } from '@components/ui/button/button';
import { useRouter } from 'next/router';
import { CountPicker } from '@components/ui/count-picker/count-picker';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { ProductLarge } from '@components/product/elements/product-large/product-large';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';

export const SingleProduct: FCC<SingleProductProps> = observer((props) => {
  const { modal, favorites, cart, ui } = useStore();
  const router = useRouter();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const ratingPlural = plural(props.ratingCount, '%d оценка', '%d оценки', '%d оценок');
  const offersPlural = plural(
    props?.offersCount,
    '%d предложение',
    '%d предложения',
    '%d предложений'
  );
  const reviewsPlural = plural(props.reviewsCount, '%d отзыв', '%d отзыва', '%d отзывов');

  const isInCart = cart.has(props.id);
  const item = cart.items.get(props.id);
  const oldPrice = useMemo(
    () => (props.price / (100 - props.discount)) * 100,
    [props.price, props.discount]
  );
  const isFavorite = favorites.has(props.id);

  const addToFavorites = (): void => {
    if (isFavorite) {
      return favorites.remove(props.id);
    }
    favorites.add({
      id: props.id,
      name: props.name,
      slug: props.slug,
      price: props.price,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      images: props.images,
      hit: props.hit,
      rating: props.rating,
      ratingCount: props.ratingCount,
      discount: props.discount,
    });
  };

  const addToCart = (): void => {
    cart.addItem({
      id: props.id,
      name: props.name,
      price: props.price,
      slug: props.slug,
      images: props.images,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      hit: props.hit,
      rating: props.rating,
      ratingCount: props.ratingCount,
      discount: props.discount,
    });
  };
  const increaseCount = () => {
    cart.increaseCount(item.id);
  };
  const decreaseCount = () => {
    cart.decreaseCount(item.id);
  };

  const mainImage = props.images[selectedSlide]
    ? `http:/${props.images[selectedSlide]}`
    : undefined;

  const openModal = () => {
    if (props.images.length === 0) return;

    modal.show('imageSlider', {
      images: props.images,
      current: selectedSlide,
      name: props.name,
    });
  };

  const openOfferModal = () =>
    modal.show('oneClickOrder', {
      ...props,
      discount: props.discount,
      image: props.images[0],
    });

  const goToProduct = () => router.push(`/p/${props.slug}`);

  const goToProductReviews = () => router.push(`/p/${props.slug}/reviews`);

  const getValueToSliceVideos = () => {
    if (ui.viewport <= 1312) return 3;

    return 4;
  };

  if (ui.viewport <= 1110)
    return (
      <S.StyledSingleProduct>
        <ProductLarge productsCount={1} {...props} />
      </S.StyledSingleProduct>
    );

  return (
    <S.StyledSingleProduct>
      <S.SingleProductStickyWrapper className="image">
        <S.SingleProductImageContainer>
          <S.Labels>
            {props.hit && <Label color="purple">Хит</Label>}
            {props.discount > 0 && <Label color="pink">-{props.discount}%</Label>}
          </S.Labels>

          <S.SingleProductImage onClick={openModal}>
            {mainImage ? (
              <Image
                src={mainImage}
                loader={imageLoader({
                  src: mainImage,
                  width: 640,
                  useSrcOnly: true,
                })}
                width={270}
                height={224}
                fill
                style={{ objectFit: 'contain' }}
                alt={props.name}
                title={props.name}
              />
            ) : (
              <Image
                src="s3://pics/nophotobig.svg"
                fill
                style={{ objectFit: 'contain' }}
                width={270}
                height={224}
                alt={props.name}
                title={props.name}
              />
            )}
          </S.SingleProductImage>
        </S.SingleProductImageContainer>

        <S.SingleProductImagesSlider>
          <SingleProductImagesSlider
            openModal={openModal}
            images={props.images}
            name={props.name}
            setSelectedSlide={setSelectedSlide}
          />
        </S.SingleProductImagesSlider>
      </S.SingleProductStickyWrapper>

      <S.SingleProductMain>
        <Link legacyBehavior passHref href={`/p/${props.slug}`}>
          <S.SingleProductTitle>{props.name}</S.SingleProductTitle>
        </Link>

        <S.SingleProductMainInfo>
          <RatingStars
            hideStarsOnTabletM
            hideStarsOnTabletS
            rating={props.rating}
            votes={props.ratingCount}
          />
          <NoSSR>
            <S.PackageInfoRatingFavorites isFavorite={isFavorite}>
              <TextWithIcon
                onClick={addToFavorites}
                icon={isFavorite ? IconHeartFilled : IconHeart}
              >
                {isFavorite ? 'В избранном' : 'В избранное'}
              </TextWithIcon>
            </S.PackageInfoRatingFavorites>
          </NoSSR>
          <S.PackageInfoRatingValue>
            <TextWithIcon className="reviews" icon={IconMessage} onClick={goToProductReviews}>
              {reviewsPlural}
            </TextWithIcon>
          </S.PackageInfoRatingValue>
        </S.SingleProductMainInfo>

        <ProductInformation
          attributes={props.attributes}
          reviewsCount={props.reviewsCount}
          slug={props.slug}
          tinyTitle
        />

        {props.videos && props.videos.length > 0 && (
          <S.SingleProductBlockWrapper>
            <S.SingleProductBlockTitle>Видео</S.SingleProductBlockTitle>

            <S.SingleProductVideos>
              {props.videos.slice(0, getValueToSliceVideos()).map((video) => (
                <MainVideo key={Math.random()} {...video} />
              ))}
            </S.SingleProductVideos>
          </S.SingleProductBlockWrapper>
        )}

        {!!props.description && (
          <S.SingleProductBlockWrapper>
            <S.SingleProductBlockTitle>Описание</S.SingleProductBlockTitle>

            <ProductDescription text={props.description} />
          </S.SingleProductBlockWrapper>
        )}

        <S.SingleProductButton>
          <Button onClick={goToProduct} outline>
            Подробнее о товаре
          </Button>
        </S.SingleProductButton>
      </S.SingleProductMain>

      <S.SingleProductStickyWrapper className="price">
        <S.SingleProductPriceTop>
          <S.SingleProductPrice>{props.price.toLocaleString('ru-RU')} ₽</S.SingleProductPrice>

          {!!oldPrice && (
            <S.SingleProductPriceOld>
              {Number(oldPrice.toFixed()).toLocaleString('ru-RU')} ₽
            </S.SingleProductPriceOld>
          )}
        </S.SingleProductPriceTop>

        {props.priceTo !== props.priceFrom && (
          <S.SingleProductPriceRange>
            от {props.priceFrom.toLocaleString('ru-RU')} до {props.priceTo.toLocaleString('ru-RU')}{' '}
            ₽
          </S.SingleProductPriceRange>
        )}

        <S.SingleProductPriceOffers>{offersPlural}</S.SingleProductPriceOffers>

        <S.SingleProductPriceButtons>
          <Button outline onClick={openOfferModal}>
            Хочу купить
          </Button>

          <S.SingleProductPriceToCart>
            {!isInCart ? (
              <Button onClick={addToCart}>В корзину</Button>
            ) : (
              <CountPicker count={item.count} onMinus={decreaseCount} onPlus={increaseCount} />
            )}
          </S.SingleProductPriceToCart>
        </S.SingleProductPriceButtons>
      </S.SingleProductStickyWrapper>
    </S.StyledSingleProduct>
  );
});
