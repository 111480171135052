import React from 'react';
import * as S from './single-product-images-slider-styles';
import { Product } from '../../../../../gql/generated';
import { Image } from '@components/Image';

type Props = {
  images: Product['images'];
  name: string;
  setSelectedSlide: React.Dispatch<number>;
  openModal: () => any;
};

export const SingleProductImagesSlider: FCC<Props> = (props) => {
  const moreImagesCount = props.images.length - 3;

  return (
    <S.StyledSingleProductSlider>
      {props.images.slice(0, 3).map((image, idx) => (
        <S.SingleProductSliderItem
          key={`image-${idx}-${image}`}
          onClick={() => props.setSelectedSlide(idx)}
        >
          <Image
            src={`s3:/${image}`}
            width={77}
            height={60}
            style={{ objectFit: 'contain' }}
            alt={props.name}
            title={props.name}
          />
        </S.SingleProductSliderItem>
      ))}

      {moreImagesCount > 0 && (
        <S.SingleProductSliderItemBordered onClick={props.openModal}>
          + Ещё {moreImagesCount}
        </S.SingleProductSliderItemBordered>
      )}
    </S.StyledSingleProductSlider>
  );
};
