import styled from 'styled-components';

export const StyledSingleProductSlider = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;
`;

export const SingleProductSliderItem = styled.div`
  width: 77px;
  height: 60px;
  cursor: pointer;
  border-radius: 3px;
`;

export const SingleProductSliderItemBordered = styled(SingleProductSliderItem)`
  color: #686a70;
  font-size: 12px;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f9f7fa;
`;
