import React, { useCallback, useRef, useState } from "react";
import { ProductCard, ProductCardType } from "@components/product/elements/product-card/product-card";
import * as S from "./responsive-product-cards.styles";

type ResponsiveProductCardsProps = Readonly<{
  products: ProductCardType[];
  excludeIDs?: string[];
}>;

export const ResponsiveProductCards: FCC<ResponsiveProductCardsProps> = ({
  products,
  excludeIDs,
}) => {
  const [excluded, _] = useState(new Set(excludeIDs));
  const scrollableParent = useRef<HTMLDivElement>();

  const disableScroll = useCallback((e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }, []);

  const onTouchStart = (e: React.TouchEvent) => {
    scrollableParent.current.addEventListener('touchmove', disableScroll, { passive: false });
  };

  const onTouchEnd = (e: React.TouchEvent) => {
    scrollableParent.current.removeEventListener('touchmove', disableScroll);
  };

  return (
    <S.ResponsiveProductCardsWrapper>
      <S.StyledResponsiveProductCards count={products.length} ref={scrollableParent}>
        {products.map(
          (product) =>
            !excluded.has(product.id) && (
              <ProductCard
                key={product.id}
                product={product}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
              />
            )
        )}
      </S.StyledResponsiveProductCards>
    </S.ResponsiveProductCardsWrapper>
  );
};
