import {
  ProductCategoryCount,
  ProductCategoryLeft,
  ProductCategoryShowMore,
  ProductCategoryTitle,
  StyledProductCategory,
} from '@components/main/elements/product-category/product-category.style';
import { device } from '@components/ui/theme';
import styled from 'styled-components';
import { BlockTitle } from '@components/ui/block-title/block-title';

export const StyledProductCategories = styled.section`
  margin-top: 80px;
  padding-bottom: 40px;

  ${BlockTitle} {
    margin-bottom: 40px;
  }

  &.smallerMarginTop {
    margin-top: 40px;
  }

  &.noMarginTop,
  ${StyledProductCategory} {
    margin-top: 0;
  }

  ${ProductCategoryShowMore} {
    color: #825bd3;
  }

  @media (max-width: 1440px) {
    padding-bottom: 30px;

    ${ProductCategoryTitle} {
      font-size: 24px;
      line-height: 28px;
    }

    ${ProductCategoryCount} {
      margin-right: 20px;
    }

    ${StyledProductCategory} {
      margin-top: 30px;
      flex-direction: column;

      ${ProductCategoryLeft} {
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    &.noMarginTop,
    ${StyledProductCategory} {
      margin-top: 0 !important;
    }
  }

  @media (max-width: 1050px) {
    margin-top: 28px;

    &.noMarginTop {
      margin-top: 0;
    }

    ${StyledProductCategory} ${ProductCategoryLeft} {
      justify-content: flex-start;

      ${ProductCategoryCount} {
        margin-left: 15px;
      }
    }
  }

  @media (max-width: 660px) {
    ${ProductCategoryCount} {
      margin-right: 10px;
    }

    ${ProductCategoryTitle} {
      line-height: 24px;
    }
  }

  @media ${device.mobileM} {
    ${ProductCategoryTitle} {
      font-size: 20px;
    }

    ${StyledProductCategory} {
      margin-top: 35px;
    }

    &.noMarginTop,
    &.noMarginTop ${StyledProductCategory} {
      margin-top: 0;
    }
  }

  @media (max-width: 350px) {
    ${ProductCategoryTitle} {
      max-width: 70%;
    }
  }
`;
