import React from "react";
import * as S from "./product-large-cards-style";
import { ProductLarge, ProductLargeType } from "@components/product/elements/product-large/product-large";

type Props = {
  products: ProductLargeType[];
};

export const ProductLargeCards: FCC<Props> = (props) => {
  return (
    <S.StyledProductLargeCards>
      {props.products.map((p) => (
        <ProductLarge productsCount={props.products.length} key={`large-${p.id}`} {...p} />
      ))}
    </S.StyledProductLargeCards>
  );
};
