import { scrollbar } from '@components/ui/theme';
import styled from 'styled-components';
import { StyledProductCard } from '@components/product/elements/product-card/product-card.style';

export const StyledResponsiveProductCards = styled.div<{ count: number }>`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  gap: 20px;
  overflow: scroll;
  ${scrollbar.vertical}
  width: 100%;
  max-width: 100%;
  background-clip: padding-box;

  ::-webkit-scrollbar-thumb {
    border-left: 30px white solid;
    border-right: 30px white solid;
  }

  ${StyledProductCard} {
    min-width: 31%;
  }

  @media (max-width: 1000px) {
    ${scrollbar.alignSidesDesktop}
    width: 100vw;
    padding-left: 10px;
    min-width: unset;
    max-width: unset;
    left: -10px;
  }

  @media (max-width: 941px) {
    ${StyledProductCard} {
      min-width: 34%;
    }
  }

  @media (max-width: 861px) {
    ${StyledProductCard} {
      min-width: 37%;
    }
  }

  @media (max-width: 800px) {
    ${StyledProductCard} {
      min-width: 42%;
    }
  }

  @media (max-width: 710px) {
    ${StyledProductCard} {
      min-width: 46%;
    }
  }

  @media (max-width: 650px) {
    ${StyledProductCard} {
      min-width: 54%;
    }
  }

  @media (max-width: 424px) {
    ${StyledProductCard} {
      min-width: 63%;
    }
  }

  @media (max-width: 369px) {
    ${StyledProductCard} {
      min-width: 67%;
    }
  }

  @media (max-width: 349px) {
    ${StyledProductCard} {
      min-width: 72%;
    }
  }

  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    ::-webkit-scrollbar-thumb {
      border-left: 20px white solid;
      border-right: 20px white solid;
    }
  }

  @media (max-width: 480px) {
    margin-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ResponsiveProductCardsWrapper = styled.div`
  padding-bottom: 24px;
`;
