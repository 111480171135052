import React from 'react';
import * as S from './product-category.style';
import { ProductCardsSlider } from '@components/product/blocks/product-cards-slider/product-cards-slider';
import plural from 'plural-ru';
import Link from 'next/link';
import { Category } from '../../../../gql/generated';
import { ProductCardType } from '@components/product/elements/product-card/product-card';
import { ResponsiveProductCards } from './responsive-product-cards';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import { Button } from '@components/ui/button/button';
import { ProductLargeType } from '@components/product/elements/product-large/product-large';
import { useRouter } from 'next/router';

export type ProductCategory = Pick<Category, 'slug' | 'name' | 'productsCount' | 'id'> & {
  products: ProductCardType[] | ProductLargeType[];
  onlyCards?: boolean;
};

type Props = ProductCategory;

export const ProductCategory = observer((props: Props) => {
  const { ui } = useStore();
  const router = useRouter();
  const count = plural(props.productsCount, '%d товар', '%d товара', '%d товаров');

  const goToCategory = () => {
    if (ui.viewport <= 480) router.push(`/c/${props.slug}`);
  };

  const cardsRenderer = () => {
    if (ui.viewport > 1001) {
      return <ProductCardsSlider useFourSlides products={props.products} />;
    }

    return <ResponsiveProductCards products={props.products} />;
  };

  return (
    <S.StyledProductCategory>
      <S.ProductCategoryLeft>
        <S.ProductCategoryTitle>
          <Link legacyBehavior prefetch={false} href={`/c/${props.slug}`}>
            {props.name}
          </Link>
        </S.ProductCategoryTitle>
        <S.ProductCategoryInfo>
          <S.ProductCategoryCount onClick={goToCategory}>{count}</S.ProductCategoryCount>
          <S.ProductCategoryShowMore>
            <Link legacyBehavior prefetch={false} href={`/c/${props.slug}`}>
              Смотреть все
            </Link>
          </S.ProductCategoryShowMore>
        </S.ProductCategoryInfo>
      </S.ProductCategoryLeft>

      <S.ProductCategoryRight count={props.products.length}>
        {cardsRenderer()}
      </S.ProductCategoryRight>

      <Link legacyBehavior prefetch={false} href={`/c/${props.slug}`} passHref>
        <S.ProductCategoryShowAll>
          <Button secondary>Смотреть все</Button>
        </S.ProductCategoryShowAll>
      </Link>
    </S.StyledProductCategory>
  );
});
